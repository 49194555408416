@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/style.css");
@import url("./assets/css/animate.css");
@import url("./assets/css/testimonial.css");

.fadeInUp {
  /* Add your animation properties here */
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.styles-module_image__2hdkJ {
  height: auto !important;
  width: auto !important;
  z-index: 1100 !important;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.zoomIn {
  animation-name: zoomIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.image-portfolio-div img:hover {
  /* transform: scale(2.1); */
  transition: transform 0.3s ease;
}
.portfolio-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  border-radius: 4px;
}

.image-portfolio-div:hover .portfolio-overlay {
  display: block;
}

.portfolio-overlay-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Set the height to 100% to fill the parent element */
  text-align: center; /* Center text within the container */
  color: white; /* Text color (you can adjust this) */
  padding: 10px; /* Adjust padding as needed */
}

.portfolio-overlay-details h5 {
  margin: 0; /* Remove default margin for the heading */
}

.portfolio-overlay-details p {
  margin: 0; /* Remove default margin for the paragraph */
}

.text-6 {
  font-size: 1.5em !important;
}

.fa,
.fas {
  font-weight: 900;
  font-size: 2em;
}

.navbar-nav .nav-link {
  font-weight: normal !important;
}

.justify-p {
  /* text-align: justify; */
}

.btn-close {
  color: var(--bs-primary);
}
.btn-top {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(12, 12, 12);
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  line-height: 60px; /* Center the text vertically */
}

.btn-top {
  /* Your existing styles here */

  animation: moveUp 1s infinite; /* Adjust the animation duration as needed */
}

@keyframes moveUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(
      -5px
    ); /* Adjust the distance you want it to move up */
  }
}

/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track (the area on the sides of the thumb) */
::-webkit-scrollbar-track {
  background: var(--bs-body-bg);
  /* background: var(--bs-primary); */
}

/* Handle (the draggable scrolling part) */
::-webkit-scrollbar-thumb {
  /* background: #888; */

  background: var(--bs-primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  background: var(--bs-body-color);
}
